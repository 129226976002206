import React from 'react'
import styled from 'styled-components';
import { Typography, TableContainer, TableHead, TableBody, Table, TableCell, TableRow, Tooltip } from '@material-ui/core'
import { connect } from 'react-redux';
import { loadReports } from '../Store/Actions/userAction'
import TableLoader from './TableLoader'

function ReportsTable({ reports, loadReports, reportsLoader }) {

    const messageTruncate = message => {
        if(message.length <= 25) {
            return message;
        }
        else {
            return message.substring(0, 25) + '...'
        }
    }

    React.useEffect(() => {
       reports === null && loadReports();     
    },[reports, loadReports])

    return (
        <Container>
            <Header>
                <Typography variant='subtitle2' color='textPrimary' style={{ fontSize : '16px' }}>
                      SMS History
                </Typography>
            </Header>
            <StyledTableContainer>
              {!reportsLoader ? 
                  reports && !reports.length !== 0 ?
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>S.N</TableCell>
                                <TableCell align="center">Message</TableCell>
                                <TableCell align="center">Receivers</TableCell>
                                <TableCell align="right">Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                              reports.map((report, index) =>
                                <TableRow key={index}>
                                    <TableCell>{++index}</TableCell>
                                    <TableCell align="center">
                                        <StyledTooltip title={report.message} 
                                                placement="top">
                                            <div style={{ display : 'block', cursor : 'pointer' }}>
                                                {messageTruncate(report.message)}
                                            </div>
                                        </StyledTooltip>
                                    </TableCell>
                                    <TableCell align="center">{report.receivers}</TableCell>
                                    <TableCell align="right">
                                    {report.sentDate.split(' ')[0]}
                                    </TableCell>
                                </TableRow>
                              )  
                            }
                        </TableBody>
                    </Table>
                    :
                   <Typography variant='subtitle2' 
                               color='textSecondary'
                               align= 'center' 
                               style={{ fontWeight : '400', padding : '1rem 0rem' }}>
                         No SMS history found.           
                   </Typography>
                :    
                <TableLoader />
                }
            </StyledTableContainer>
        </Container>
    )
}

const mapStateToProps = state => {
    return {
        reports : state.userReducer.reports,
        reportsLoader : state.userReducer.reportsLoader,
    }
}

const mapDispatchtoProps = dispatch => {
    return {
        loadReports : () => dispatch(loadReports())
    }
}

export default connect(mapStateToProps, mapDispatchtoProps)(ReportsTable);

const Container = styled.div`
    margin-bottom : 20px;
    border-radius : 10px;
    width : 100%;
    background : #fff;
    box-sizing : border-box;
    box-shadow: rgba(53, 64, 82, 0.05) 1px 0px 14px 0px;
`
const Header = styled.div`
   height : 60px;
   padding : 0px 20px;
   display : flex;
    box-sizing : border-box;
   align-items : center;
   justify-content : space-between;
   border-bottom : 1px solid rgba(0, 0, 0, 0.12);
`
const StyledTableContainer = styled(TableContainer)`
    &&& {
        padding : 0rem 3rem 1rem;
        box-sizing : border-box;
        width : 100%;
        @media(max-width : 768px) {
            padding : 0px;
        }
    }
`;
const StyledTooltip = styled(Tooltip)`
   &&& {
       .MuiTooltip-tooltip {
           font-size : 1rem;
        }
   }
`;
