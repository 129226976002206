import React from 'react'
import styled from 'styled-components';
import { Typography, Button } from '@material-ui/core'
import  { logOut } from '../Store/Actions/authAction';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom'

function LogoutModal({ logOut, handleClose }) {

    const history = useHistory()

    const logOutHandler = () => {
        logOut();
        history.push('/');
    }

    return (
        <Container>
            <div className='modal'>
                <Typography variant='subtitle2' color='textSecondary'
                            style={{ fontWeight : '400' }}>
                     Are you sure, you want to log out?           
                </Typography>
                <div className='action'>
                    <Button size='small' color='secondary' 
                            style={{ marginRight : '7px', textTransform : 'capitalize' }}
                            variant='contained' 
                            onClick={logOutHandler}
                            disableElevation>
                        Log out
                    </Button>
                    <Button size='small' 
                            style={{ textTransform : 'capitalize' }}
                            variant='contained' 
                            onClick={handleClose}
                            disableElevation>
                        Cancel
                    </Button>
                </div>
            </div>
        </Container>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        logOut : () => dispatch(logOut())
    }
}

export default connect(null, mapDispatchToProps)(LogoutModal);

const Container = styled.div`
   position : fixed;
   top : 0px;
   left : 0px;
   height : ${window.innerHeight}px;
   width : 100vw;
   background : rgba(0, 0, 0, 0.4);
   z-index : 1300; 
   display : flex;
   align-items : center;
   justify-content : center;
   .modal {
       background : #fff;
       border-radius : 4px;
       padding : 1rem;
       margin : auto 10px;
       box-sizing : border-box;
   }
   .action {
       margin-top : 10px;
       display : flex;
       justify-content : flex-end;
       width : 250px;
       @media(max-width : 768px) {
           width : 80vw;
       }
   }
`;
