import React from 'react'
import styled from 'styled-components'
import { Typography, Divider } from '@material-ui/core'
import { Link } from 'react-router-dom'

function HelpBox() {
    return (
        <Container>
             <Header>
                <Typography variant='subtitle2' color='textPrimary' style={{ fontSize : '16px' }}>
                        Quick Tips
                </Typography>  
             </Header>
             <Body>   
                <Typography variant='subtitle2' style={{ fontSize : '14px', fontWeight : '400', color : '#757575' }}>
                           <Divider style={{ margin : '0px 0px 7px' }}/>
                           <div> Send SMS easily by selecting groups.  </div>
                           <Divider style={{ margin : '7px 0px' }}/>
                           <div> Import contact from excel files.  </div>
                           <Divider style={{ margin : '7px 0px' }}/>
                           <div> Tired of importing contacts?
                               <Link to='/manage-groups' style={{ color : '#1A73E8' }}> Use manage groups</Link> 
                           </div>
                           <Divider style={{ margin : '7px 0px' }}/>
                           <div> API integration for developers is in .
                               <Link to='/manage-api' style={{ color : '#1A73E8' }}>Manage api</Link> 
                            </div>
                           <Divider style={{ margin : '7px 0px' }}/>
                </Typography>  
            </Body>    
        </Container>
    )
}

export default HelpBox;

const Container = styled.div`
    box-sizing : border-box;
    background : #fff;
    border-radius : 10px;
    box-shadow: rgba(53, 64, 82, 0.05) 1px 0px 14px 0px;
    width : 100%;
`
const Header = styled.div`
   height : 50px;
   padding : 0px 20px;
   display : flex;
   flex-direction : column;
   justify-content : center;
`
const Body = styled.div`
   padding : 0px 20px;
   padding-bottom : 20px;
`