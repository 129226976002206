import React from 'react'
import styled from 'styled-components';
import { Typography } from '@material-ui/core';

function ServiceComponent() {
    return (
        <Container>
              <Service style={{ borderRight : '1px solid #fb8c00' }}>
                    <Typography variant='body1' align='center' style={{ fontWeight : '500', marginBottom : '10px' }}>
                            Bulk Sms
                     </Typography> 
                     <div className='serviceText' style={{ lineHeight : '1.5rem' }}>
                         We allow our customer's to send SMS in bulk, use either our elegant website or create your own.  
                      </div>     
              </Service>
              <Service  style={{ borderRight : '1px solid #fb8c00' }}>
                    <Typography variant='body1' align='center' style={{ fontWeight : '500', marginBottom : '10px' }}>
                         Cost Effective
                     </Typography> 
                     <div className='serviceText'style={{ lineHeight : '1.5rem' }}>
                           Our SMS solution is the cheapest in the Nepalese market right now.  
                      </div>     
              </Service> 
              <Service>
                    <Typography variant='body1' align='center' style={{ fontWeight : '500', marginBottom : '10px' }}>
                         API Integration
                     </Typography> 
                     <div className='serviceText' style={{ lineHeight : '1.5rem' }}>
                         We let allow our API integration into your system. Just follow the documentation and get started
                         in few seconds.   
                      </div>     
              </Service>  
        
        </Container>
    )
}

export default ServiceComponent;

const Container = styled.div`
    display : flex;
    justify-content : center;
    margin-bottom : 20px;
    margin-top : 10px;
    @media (max-width : 992px) {
        flex-direction : column;
    }
`
const Service = styled.div`
    box-sizing : border-box;
    padding : 20px;
    margin-top : 30px;
    .serviceText {
        font-size : 14px;
        text-align : center;
        color : #757575;
    }
`



