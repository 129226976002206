import React from 'react'
import styled from 'styled-components';
import { CircularProgress, Paper, Typography, IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { motion } from 'framer-motion'

const loaderVariant = {
    from : { opacity : 0, x : -10 },
    to : {
        opacity : 1,
        x : 0,
        transition : {
            type : 'tween',
            duration : 0.2
        }
    }
}

function Loader({ message }) {

    const [hide, setHide] = React.useState(false);

    return (
       !hide && <Container variants={loaderVariant} initial='from' animate='to'> 
            <StyledPaper elevation={7}>
                <CircularProgress color='primary' size={20}/>
                <Typography variant='subtitle2' color='textSecondary' 
                            style={{ marginLeft : '10px', fontWeight : '400' }}>
                        { message ? 'Sending SMS....' : 'Verifying account...' }
                </Typography>
                <IconButton  onClick={() => setHide(true)}>
                    <ClearIcon fontSize='small'/>
                </IconButton>
            </StyledPaper>
        </Container>
    )
}

export default Loader;

const Container = styled(motion.div)`
   position : fixed;
   top : 10px;
   left : 10px; 
   z-index : 99;
`
const StyledPaper = styled(Paper)`
    &&& {
        box-sizing : border-box;
        display : flex;
        padding : 0.3rem 1rem;
        align-items : center;
    } 
`
