import React from 'react'
import styled from 'styled-components';
import { Paper, Typography, Button, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';

function OfferBanner({ handleOpen, handleClose }) {
    return (
        <Container elevation={15}>
              <IconButton onClick={handleClose}>
                <CloseIcon style={{ color : '#ff5c62' }}/>
              </IconButton>
              <div className='item'>
                <Typography style={{ color : '#ff5c62', fontSize : '1.2rem' }} variant='subtitle2' >
                       Register now and get a bonus of 10 sms for free. 
                 </Typography>
                 <RegisterButton variant='contained' onClick={handleOpen}>
                            Register now
                </RegisterButton>  
              </div>
        </Container>
    )
}

export default OfferBanner;

const Container = styled(Paper)`
   position : fixed;
   width : 100vw;
   bottom : 0px;
   left : 0px;
   box-sizing : border-box;
   padding : 1rem;
   z-index : 1200;  
   display : flex;
   align-items : center;
   justify-content : center;
   &&& {
        -webkit-box-shadow: 0px -2px 27px 0px rgba(0,0,0,0.26);
        -moz-box-shadow: 0px -2px 27px 0px rgba(0,0,0,0.26);
        box-shadow: 0px -2px 27px 0px rgba(0,0,0,0.26);
   }
   .item {
        flex : 1;
        display : flex;
        justify-content : center; 
   }
   @media(max-width : 768px) {
       display : none;
   }
`;
const RegisterButton = styled(Button)`
    &&& {
        color : #fff;
        ${({ theme }) => `background : ${theme.palette.success.main}`};
        margin-left : 10px;
    }
`
