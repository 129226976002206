import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { motion, AnimatePresence } from 'framer-motion';
import { Typography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux'
import { fetchMonthlyStats } from '../Store/Actions/userAction'

const slideVaiant = {
    from : {
        y : '100%',
        opacity : 0,
    },
    to : {
        y : 0,
        opacity : 1,
        transition : {
            type : 'tween',
            duration : 0.4
        } 
    },
    exit : {
        y : '-100%',
        opacity : 0,
        zIndex : 1,
        transition : {
            type : 'tween',
            duration : 0.4
        } 
    }
}

function StatCard() {

    const [changeMonth, setChangeMonth] = useState(false);
    const totalSent = useSelector(state => state.userReducer.sent);
    const dispatch = useDispatch()

    const changeMonthHandler = () => {
        setChangeMonth(prev => !prev)
    }

    useEffect(() => {
        dispatch(fetchMonthlyStats(changeMonth)) 
    }, [changeMonth, dispatch])

    return (
        <StyledStatCard className='statCard' style={{ marginRight : '7px', background : '#1A73E8' }}>
                <div className='title'>
                   <div style={{ display : 'flex', alignItems : 'center', cursor : 'pointer', justifyContent : 'space-between' }}
                        onClick={changeMonthHandler}>
                        <div></div>    
                        <AnimatePresence initial={false}>
                           {!changeMonth ? 
                                <AnimTypography variant='caption'
                                                        variants={slideVaiant} 
                                                        key={false}
                                                        initial='from'
                                                        animate='to'
                                                        exit='exit'>
                                            This month
                                </AnimTypography>
                                :
                                <AnimTypography variant='caption'
                                        variants={slideVaiant} 
                                        key={true}
                                        initial='from'
                                        animate='to'
                                        exit='exit'>
                                            Previous month
                                </AnimTypography>
                            } 
                        </AnimatePresence>    
                        <ArrowDropDownIcon fontSize='small' style={{  marginRight : '2rem' }}/>
                   </div> 
                   <Typography variant='subtitle2' style={{  fontWeight : '400' }}>
                            Total Sent
                   </Typography>  
                </div>    
                <div>
                    <Typography variant='subtitle2' className='number' component='div' align='right' style={{ fontFamily : 'Kanit' }}>
                        {totalSent && totalSent}
                    </Typography> 
                </div>    
        </StyledStatCard>
    )
}


export default StatCard;

const StyledStatCard = styled.div`
    color : #fff ;
    box-sizing : border-box;
    padding : 5px 10px;
    display : flex;
    height : 70px;  
    width : 200px;
    border-radius : 3px;
    justify-content : space-between;
    align-items : flex-end;
    background: #F27121;
    .title {
        height : 100%;
        flex : 1;
        display : flex;
        flex-direction : column;
        justify-content : space-evenly;
    }
    .number {
        font-size : 25px;
    }
    @media( max-width : 768px ) {
        margin-top : 10px;
    }
`;

const AnimTypography = motion.custom(styled(Typography)`
    &&& {
        font-weight : 400; 
        color : #eee;
        position : absolute;
        flex : 1;
    }
`)
