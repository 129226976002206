import React from 'react'
import styled from 'styled-components';
import { Grid, Typography } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CallIcon from '@material-ui/icons/Call';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LanguageIcon from '@material-ui/icons/Language';

function Contactus() {
    return (
        <Container>
            <Grid container spacing={6} justify="center">
                <Grid item xs={12} sm={12} md={6} lg={5}> 
                    <iframe title='Sms Nepal' width="100%" height="auto" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=putalisadak+(SMS%20Nepal)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
                    </iframe>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={5}> 
                    <div>
                        <Typography variant='subtitle2' 
                                    gutterBottom
                                    style={{ color : '#fff', fontSize : '1.2rem' }}>
                              Contact info
                        </Typography>
                        <div className='infos'>
                            <LocationOnIcon fontSize='small' color='inherit'/>
                            <Typography variant='subtitle2' 
                                    gutterBottom
                                    style={{ color : '#fff',
                                             fontSize :'0.9rem',
                                             fontWeight : '400',
                                             marginLeft : '10px' }}>
                                Putalisadak chowk, Kathmandu
                            </Typography>
                        </div>
                        <div className='infos'>
                            <CallIcon fontSize='small' color='inherit'/>
                            <a href='tel:+9779843873828' style={{ textDecoration : 'none' }}>
                                <Typography variant='subtitle2' 
                                        gutterBottom
                                        style={{ color : '#fff',
                                                fontSize :'0.9rem',
                                                fontWeight : '400',
                                                marginLeft : '10px' }}>
                                    +977 9843873828, +977 9840219384
                                </Typography>
                            </a>
                        </div>
                        <div className='infos'>
                            <MailOutlineIcon fontSize='small' color='inherit'/>
                            <Typography variant='subtitle2' 
                                    gutterBottom
                                    style={{ color : '#fff',
                                            fontSize :'0.9rem',
                                            fontWeight : '400',
                                            marginLeft : '10px' }}>
                                smsnepal2020@gmail.com
                            </Typography>
                        </div>
                        <div className='infos'>
                            <LanguageIcon fontSize='small' color='inherit'/>
                            <Typography variant='subtitle2' 
                                    gutterBottom
                                    style={{ color : '#fff',
                                            fontSize :'0.9rem',
                                            fontWeight : '400',
                                            marginLeft : '10px' }}>
                                www.smsnepal.ga
                            </Typography>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Contactus;

const Container = styled.div`
   box-sizing : border-box;
   padding : 3rem 1rem 1rem;
   overflow : hidden;
   ${({ theme }) => `
          background : ${theme.palette.primary.main};  
   `}
   .infos {
       color : #fff;
       display : flex;
       align-items : flex-start;
       margin-bottom : 5px;
       box-sizing : border-box;
   }
`;
