export const START_REGISTRATION = 'START_REGISTRATION';
export const REGISTRATION_MAIL_SUCCESS = 'REGISTRATION_MAIL_SUCCESS';
export const REGISTRATION_FAIL = 'REGISTRATION_FAIL';

export const START_LOGIN = 'START_LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_FAIL = 'VERIFY_EMAIL_FAIL';
export const VERIFYING_EMAIL = 'VERIFYING_EMAIL';

export const UNVERIFIED_ACCOUNT = 'UNVERIFIED_ACCOUNT';
export const SENDING_EMAIL_TO_UNVERIFIED_ACCOUNT  ='SENDING_EMAIL_TO_UNVERIFIED_ACCOUNT';
export const SENDING_EMAIL_TO_UNVERIFIED_ACCOUNT_SUCCESS = 'SENDING_EMAIL_TO_UNVERIFIED_ACCOUNT_SUCCESS';
export const SENDING_EMAIL_TO_UNVERIFIED_ACCOUNT_FAIL = 'SENDING_EMAIL_TO_UNVERIFIED_ACCOUNT_FAIL';

export const SET_SUCCESS = 'SET_SUCCESS';
export const SET_ERROR = 'SET_ERROR';

export const LOADING_USER_DATA = 'LOADING_USER_DATA';
export const LOAD_USER_DATA_SUCCESS = 'LOAD_USER_DATA_SUCCESS';
export const LOAD_USER_DATA_FAIL = 'LOAD_USER_DATA_FAIL';

export const RESEND_EMAIL_SUCCESS = 'RESEND_EMAIL_SUCCESS';
export const RESEND_EMAIL_FAIL = 'RESEND_EMAIL_FAIL';
export const RESENDING_EMAIL = 'RESENDING_EMAIL';

export const SENDING_EMAIL_FOR_RESET = 'SENDING_EMAIL_FOR_RESET'
export const SENDING_EMAIL_FOR_RESET_SUCCESS = 'SENDING_EMAIL_FOR_RESET_SUCCESS'
export const SENDING_EMAIL_FOR_RESET_FAIL= 'SENDING_EMAIL_FOR_RESET_ERROR'

export const RESETTING_PASSWORD = 'RESETTING_PASSWORD'
export const RESETTING_PASSWORD_SUCCESS = 'RESETTING_PASSWORD_SUCCESS'
export const RESETTING_PASSWORD_FAIL = 'RESETTING_PASSWORD_FAIL'

export const SENDING_MESSAGE = 'SENDING_MESSAGE'
export const SENDING_MESSAGE_SUCCESS = 'SENDING_MESSAGE_SUCCESS'
export const SENDING_MESSAGE_FAIL = 'SENDING_MESSAGE_FAIL'

export const SUCCESS = 'SUCCESS'
export const ERROR = 'ERROR'

export const SET_USER_SUCCESS = 'SET_USER_SUCCESS'
export const SET_USER_ERROR = 'SET_USER_ERROR'
export const SET_CREATE_SUCCESS = 'SET_CREATE_SUCCESS'

export const CHANGING_PASSWORD = 'CHANGING_PASSWORD'
export const CHANGING_PASSWORD_SUCCESS = 'CHANGING_PASSWORD_SUCCESS'
export const CHANGING_PASSWORD_ERROR = 'CHANGING_PASSWORD_ERROR'

export const CHANGING_USER_DETAIL = 'CHANGING_USER_DETAIL';
export const CHANGING_USER_DETAIL_SUCCESS = 'CHANGING_USER_DETAIL_SUCCESS';
export const CHANGING_USER_DETAIL_ERROR = 'CHANGING_USER_DETAIL_ERROR';

export const LOADING_GROUPS = 'LOADING_GROUPS';
export const LOADING_GROUPS_SUCCESS = 'LOADING_GROUPS_SUCCESS';

export const CREATING_GROUPS = 'CREATING_GROUPS';
export const CREATING_GROUPS_SUCCESS = 'CREATING_GROUPS_SUCCESS';
export const CREATING_GROUPS_FAIL = 'CREATING_GROUPS_FAIL';

export const UPDATING_GROUP_NAME = 'UPDATING_GROUP_NAME';
export const UPDATING_GROUP_NAME_SUCCESS = 'UPDATING_GROUP_NAME_SUCCESS';
export const UPDATING_GROUP_NAME_ERROR = 'UPDATING_GROUP_NAME_ERROR';

export const LOADING_GROUP_LIST = 'LOADING_GROUP_LIST';
export const LOADING_GROUP_LIST_SUCCESS = 'LOADING_GROUP_LIST_SUCCESS';
export const LOADING_GROUP_LIST_ERROR = 'LOADING_GROUP_LIST_ERROR';

export const DELETE_GROUP = 'DELETE_GROUP';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_ERROR = 'DELETE_GROUP_ERROR';

export const ADDING_GROUP_MEMBER = 'ADDING_GROUP_MEMBER'
export const ADDING_GROUP_MEMBER_SUCCESS = 'ADDING_GROUP_MEMBER_SUCCESS'
export const ADDING_GROUP_MEMBER_ERROR = 'ADDING_GROUP_MEMBER_ERROR'

export const DELETEING_GROUPMEMBER = 'DELETEING_GROUPMEMBER';
export const DELETEING_GROUPMEMBER_SUCCESS = 'DELETEING_GROUPMEMBER_SUCCESS';
export const DELETEING_GROUPMEMBER_ERROR = 'DELETEING_GROUPMEMBER_ERROR';

export const UPDATING_GROUPMEMBER = 'UPDATING_GROUPMEMBER';
export const UPDATING_GROUPMEMBER_SUCCESS = 'UPDATING_GROUPMEMBER_SUCCESS';
export const UPDATING_GROUPMEMBER_ERROR = 'UPDATING_GROUPMEMBER_ERROR';

export const GENERATING_API_KEY = 'GENERATING_API_KEY'; 
export const GENERATING_API_KEY_SUCCESS = 'GENERATING_API_KEY_SUCCESS'; 
export const GENERATING_API_KEY_ERROR = 'GENERATING_API_KEY_ERROR'; 

export const SENDING_FEED_BACK = 'SENDING_FEED_BACK';
export const SENDING_FEED_BACK_SUCCESS = 'SENDING_FEED_BACK_SUCCESS';
export const SENDING_FEED_BACK_ERROR = 'SENDING_FEED_BACK_ERROR';

export const LOADING_REPORTS = 'LOADING_REPORTS';
export const LOADING_REPORTS_SUCCESS = 'LOADING_REPORTS_SUCCESS';
export const LOADING_REPORTS_ERROR = 'LOADING_REPORTS_ERROR';

export const FETCH_MONTHLY_STATS = 'FETCH_MONTHLY_STATS'
export const FETCH_MONTHLY_STATS_SUCCESS = 'FETCH_MONTHLY_STATS_SUCCESS'
export const FETCH_MONTHLY_STATS_ERROR = 'FETCH_MONTHLY_STATS_ERROR'

export const SET_ADD_GROUP_MEMBER_SUCCESS = 'SET_ADD_GROUP_MEMBER_SUCCESS'

export const FETCHING_API_KEY = 'FETCHING_API_KEY'

export const LOG_OUT = 'LOG_OUT';