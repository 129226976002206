import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components';
import { Typography, TextField, Button, Table, IconButton, CircularProgress } from '@material-ui/core'
import PublishIcon from '@material-ui/icons/Publish';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import readXlsxFile from 'read-excel-file'
import HelpIcon from '@material-ui/icons/Help';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom'
import { addGroupMember, setAddGroupMemberSuccess } from '../Store/Actions/groupsAction'
import { connect } from 'react-redux' 
import helperImg from '../Assets/helper.JPG'

function AddMember({ loader, addGroupMember, addMemberSuccess, closeModalHandler, setAddGroupMemberSuccess }) {

    const [members, setMembers] = useState([]);
    const [name, setName] = useState({ value : '', error : false });
    const [number, setNumber] = useState({ value : '', error : false });
    const fileRef = useRef(null);
    const [page, setPage] = useState(0);
    const history = useHistory();
    const [fileUploadLoader, setFileUploadLoader] = useState(false)
    const [paginationLoader, setPaginationLoader] = useState(false)

    const nameChangeHandler = e => {
        setName({ ...name, value : e.target.value, error : false })
    }

    const numberChangeHandler = e => {
        setNumber({ ...number, value : e.target.value, error : false })
    }

    const addNumberHandler = () => {
        let nameError = false;
        let numberError = false;
        if( name.value.length === 0 ) {
            nameError = true
        }
        if( number.value.length !== 10 ) {
            numberError = true
        }
        if( nameError || numberError ) {
            setName({ ...name, error : nameError })
            setNumber({ ...number, error : numberError })
        }
        else {
            setMembers(members.concat({ ...members, memberName : name.value, memberPhone : number.value }));
            setName({ ...name, value : '', error : false })
            setNumber({ ...number, value : '',  error : false })
        }
    }

    const returnKeyHandler = e => {
        if(e.keyCode === 13) {
            addNumberHandler();
        }
    }

    const deleteMemberHandler = i => {
        let newMembers = [...members];
        newMembers.splice(i, 1);
        setMembers([...newMembers]);
    }

    const fileChangeHandler = e => {
        let list = [];
        const regx = /^[9]\d{9}/;
        setFileUploadLoader(true);
        readXlsxFile(e.target.files[0]).then((rows) => {
              list = rows.map(row => {
                  console.log(rows);
                  if(regx.test(row[1])){
                    return { memberName : row[0] || 'no name', memberPhone : row[1] }
                  }
                  return false;
              });
        })
        .then(_ => {
            setFileUploadLoader(false)
            setMembers(members.concat(list.filter( filterList => filterList !==false )));
        })
    }

    const saveMembersHandler = () => {
        addGroupMember(history.location.state.groupId, members);
    }

    const infiniteScrollHandler = e => {
        if(e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight) {
            if(page * 10 >= members.length){
                return;
            }
            else
            {   
                setPaginationLoader(true);
                setTimeout(() => {
                    setPaginationLoader(false);
                    setPage(page + 1)
                },500)
            } 
        }
    }

    React.useEffect(() => {
        if(addMemberSuccess){
            setAddGroupMemberSuccess();
            history.goBack();
        }

    },[addMemberSuccess, history, setAddGroupMemberSuccess])

    useEffect(() => {
        if(!history.location.state) {
            history.push('/');
        }
    }, [history])

    React.useEffect(() => {
        document.title = 'SMS Nepal - Add members ';
    }, [])

    return (
        <Container>
       
            <IconButton edge='start' onClick={() => history.goBack()}>
                <ArrowBackIcon />
            </IconButton>    
             <div style={{ display : 'flex', justifyContent : 'space-between', alignItems : 'center', width : '100%' }}>
                <Typography variant='body1' color='textPrimary'>
                    Add member ({ history.location.state && history.location.state.groupName})
                </Typography>
                <Typography variant='subtitle2' color='textSecondary'>
                    Members :  {members.length}
                </Typography>
             </div>
             <InputContainer>
                <StyledTextField
                        label="Name"
                        variant="outlined"
                        fullWidth
                        type="text"
                        size = 'small'
                        value={name.value}
                        onChange={nameChangeHandler}
                        autoFocus
                        disabled={loader}
                        error = {name.error}
                        helperText = { name.error ? 'Enter a valid name.' : ''}
                        onKeyDown = {returnKeyHandler}
                    /> 
                <StyledTextField
                        label="Number"
                        variant="outlined"
                        fullWidth
                        type="number"
                        disabled={loader}
                        size = 'small'
                        onChange={numberChangeHandler}
                        value={number.value}
                        style={{ marginLeft : '10px' }}
                        error={number.error}
                        helperText = { number.error ? 'Number must be of 10 digits.' : '' }
                        onKeyDown = {returnKeyHandler}
                    /> 
            </InputContainer>
            <div style={{ display : 'flex', justifyContent : 'space-between' , width : '100%', marginBottom : '0.8rem' }}>
                <div style={{ display : 'flex', alignItems : 'center' }}>
                    <Button variant='contained' 
                            size='small'
                            disabled={loader}
                            disableElevation
                            startIcon={<PublishIcon fontSize='small'/>}
                            style={{ textTransform : 'capitalize' }}
                            onClick = {() => fileRef.current.click()}
                            >
                        Import
                    </Button>
                    <Helper>
                        <HelpIcon className='icon' fontSize='small' color='primary'/>
                        <div className='helperModal'>
                            <Typography variant='subtitle2' style={{ fontWeight : "400" }}>
                                You can only import excel file.
                            </Typography>
                            <Typography variant='caption' style={{ color : '#f5f5f5' }}>
                                Format for excel file is given in below image.
                            </Typography>
                            <img src={helperImg} alt=''/>
                            <Typography variant='caption' gutterBottom style={{ color : '#f5f5f5', lineHeight : '1rem' }}>
                                Always start from left most cell of your excel file.
                            </Typography>
                            <Typography variant='caption' color='secondary'>
                                * Don't upload in any other format.
                            </Typography>
                        </div>
                    </Helper>
                </div>
                <input type='file' ref={fileRef} style={{ display : 'none' }} onChange={fileChangeHandler} accept='.xlsx , .xls'/>
                <Button variant='contained' 
                        size='small'
                        disableElevation
                        disabled={loader}
                        color='primary'
                        style={{ textTransform : 'capitalize' }}
                        onClick={addNumberHandler}
                        >
                     Add
                </Button>
            </div>
           { !fileUploadLoader ?
               members.length !== 0 ? <StyledTableContainer onScroll={infiniteScrollHandler}>
                <Table size='small' stickyHeader aria-label="sticky table" >
                        <TableHead>
                            <TableRow>
                                    <TableCell>S.N</TableCell>
                                    <TableCell align="right">Name</TableCell>
                                    <TableCell align="right">Phone no</TableCell>
                                    <TableCell align="right">Delete</TableCell>
                            </TableRow>
                        </TableHead>
                    <TableBody>
                            {
                                [...members].splice(0, (page + 1) * 10).map((list, index) =>  
                                    <TableRow key={index}>
                                        {console.log('ok')}
                                            <TableCell>{index + 1}.</TableCell>
                                            <TableCell align="right">{list.memberName}</TableCell>
                                            <TableCell align="right">{list.memberPhone}</TableCell>
                                            <TableCell align="right">
                                                <IconButton size='small' onClick={deleteMemberHandler.bind(null, index)}>
                                                        <RemoveCircleOutlineIcon fontSize='small' color='secondary'/>
                                                </IconButton>
                                            </TableCell>
                                    </TableRow>
                                    )
                            }
                        </TableBody> 
                </Table>
                { paginationLoader && <Empty style={{ padding : '10px 0px' }}>
                     <CircularProgress size={20}/> 
                </Empty> 
                }
            </StyledTableContainer>
             :
            <Empty>
                    <Typography variant='subtitle2' 
                                style={{ fontSize : '13px', fontWeight : '400' }} 
                                color='textSecondary' 
                                align='center' 
                                component='div'>
                            You can add members by importing an excel file or by typing manually.
                    </Typography>
             </Empty>
             :
             <Empty>
                    <CircularProgress size={30}/> 
            </Empty>
            }
                <div style={{ display : 'flex', justifyContent : 'flex-end', width : '100%', marginTop : '0.8rem' }}>
                    <Button variant='contained' 
                            size='small'
                            color='primary'
                            disabled={loader}
                            disableElevation
                            style={{ textTransform : 'capitalize', marginRight : '7px' }}
                            onClick={saveMembersHandler}
                            >
                         {loader ? <CircularProgress size={20}/> : 'Save'}
                    </Button>
                    <Button variant='contained' 
                            size='small'
                            disabled={loader}
                            disableElevation
                            style={{ textTransform : 'capitalize' }}
                            onClick={closeModalHandler}
                            >
                        Cancel
                    </Button>
                </div>
        </Container>
    )
}

const mapStateToProps = state => {
    return {
        addMemberSuccess : state.groupsReducer.addMemberSuccess,
        loader : state.groupsReducer.addingMemberLoader,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addGroupMember : (groupId, groupList) => dispatch(addGroupMember(groupId, groupList)),
        setAddGroupMemberSuccess : () => dispatch(setAddGroupMemberSuccess())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddMember);

const Container = styled.div`
    display : flex;
  flex-direction : column;
  width : 100%;
  align-items : flex-start;
  position : relative;
  padding : 1rem;
  box-sizing : border-box;
  height : ${window.innerHeight}px;
`;
const InputContainer = styled.div`
    margin : 10px 0px 20px;
    display : flex;
    position : relative;
    width : 100%;
    .MuiFormHelperText-root {
        font-weight : 500;
    }
`
const StyledTextField = styled(TextField)`
    ${({ theme }) => `
        label {
            font-weight : 500;
        }
        .Mui-focused {
            font-weight : 400;
        }
        .MuiOutlinedInput-root {
            fieldset {
                border-color: rgba(0, 0, 0, 0.23);
            }
            &:hover fieldset {
                border-color: rgba(0, 0, 0, 0.23);
            }
            &.Mui-focused fieldset {
                border-color: ${theme.palette.primary.main};
            }
        }
        .MuiFormHelperText-root {
            top : 100%;
            margin-top : 0px;
            margin-left : 5px;
        }
        `}
`
const StyledTableContainer = styled(TableContainer)`
   &&& {
      overflow-y : auto; 
      max-height : 400px;
   }
`;
const Empty = styled.div`
  width : 100%;
  display : flex;
  justify-content : center;
  padding : 20px 0px;
`;
const Helper = styled.div`
  margin-left : 7px;
  cursor : pointer;
  position : relative;
  .helperModal {
      position : absolute;
      left : calc(100% + 7px);
      top : -10px;
      padding : 0.5rem 1rem;
      width : 250px;
      box-sizing : border-box;
      background : #272c34;
      border-radius : 3px;
      display : flex;
      flex-direction : column;
      color : #fff;
      z-index : 3;  
      border : 1px solid #272c34;
      opacity : 0;
      visibility : hidden;
      transform : translateX(10px);
      transition : 500ms all;
      &::after {
         content : ''; 
         position : absolute; 
         left : -14px;
         top : 12px;
         border-top : 7px solid transparent;
         border-right : 7px solid #272c34;
         border-bottom : 7px solid transparent;
         border-left : 7px solid transparent;
      }
      img {
          width : 200px;
          object-fit : contain;
          margin : 7px auto;
      }
  }
  .icon:hover {
    + .helperModal {
        visibility : visible;
        opacity : 1;
        transform : translateX(0px);
    }
  }
`;