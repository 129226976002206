import React from 'react'
import { Table, TableHead, TableCell, TableBody, TableRow, Hidden } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton';

function TableLoader() {
    return (
            <Table size="small">
                <TableHead>
                        <TableRow>
                            <TableCell><Skeleton variant="text" width={40}/></TableCell>
                            <TableCell align="center"><Skeleton variant="text" /></TableCell>
                            <Hidden only={['xs','sm']}>
                                <TableCell align="center"><Skeleton variant="text" /></TableCell>
                                <TableCell align="right"><Skeleton variant="text" /></TableCell>
                            </Hidden>
                        </TableRow>
                </TableHead>
                <TableBody>
                        <TableRow>
                            <TableCell><Skeleton variant="text" width={20}/></TableCell>
                            <TableCell align="center"><Skeleton variant="text" width={200}/></TableCell>
                            <Hidden only={['xs','sm']}>
                                <TableCell align="center">
                                    <Skeleton variant="text" width={40}/>
                                </TableCell>
                                <TableCell align="right">
                                    <Skeleton variant="text" width={40}/>
                                </TableCell>
                            </Hidden>    
                        </TableRow>
                        <TableRow>
                            <TableCell><Skeleton variant="text" width={20}/></TableCell>
                            <TableCell align="center"><Skeleton variant="text" width={200}/></TableCell>
                            <Hidden only={['xs','sm']}>
                                <TableCell align="center">
                                    <Skeleton variant="text" width={40}/>
                                </TableCell>
                                <TableCell align="right">
                                    <Skeleton variant="text" width={40}/>
                                </TableCell>
                            </Hidden>
                        </TableRow>
                        <TableRow>
                            <TableCell><Skeleton variant="text" width={20}/></TableCell>
                            <TableCell align="center"><Skeleton variant="text" width={200}/></TableCell>
                            <Hidden only={['xs','sm']}>
                                <TableCell align="center">
                                    <Skeleton variant="text" width={40}/>
                                </TableCell>
                                <TableCell align="right">
                                    <Skeleton variant="text" width={40}/>
                                </TableCell>
                             </Hidden>   
                        </TableRow>
                        <TableRow>
                            <TableCell><Skeleton variant="text" width={20}/></TableCell>
                            <TableCell align="center"><Skeleton variant="text" width={200}/></TableCell>
                            <Hidden only={['xs','sm']}>
                                <TableCell align="center">
                                    <Skeleton variant="text" width={40}/>
                                </TableCell>
                                <TableCell align="right">
                                    <Skeleton variant="text" width={40}/>
                                </TableCell>
                             </Hidden>   
                        </TableRow>
                </TableBody>
            </Table>
    )
}

export default TableLoader
